<template lang="pug">
.wrapper.q-pb-xl
    .col-12(v-if="!isActivAccessibility")
        carousel-skeleton.q-mt-xl(v-if="!advertismentItemsTop.length")
        carousel-desktop(
            v-else
            :slides="advertismentItemsTop"
        )
    .col-12.carousel.q-mt-xl(v-if="discount?.length")
        product-slider-skeleton-desktop(
            title
            :height="350"
            v-if="!discount?.length ")
        product-slider-desktop(
            v-show="discount?.length"
            label="Предложения по лучшим ценам"
            :slides="discount"
            :manufacture="true"
            @previewReady="() => countPreviewTop += 1"
        )
    .col-12.q-mt-xl.carousel(v-show="advertismentItemsBottom !== null" v-if="!isActivAccessibility")
        router-link.text-primary.font-32.text-weight-bold.line-height-44.carousel__title.decoration-no(
            to="marketing-list"
            title="Акции" itemprop="text") Акции
        //carousel-skeleton.q-mt-lg(v-if="!advertismentItemsBottom?.length")
        carousel-desktop.q-mt-lg(
            v-show="advertismentItemsBottom?.length"
            :slides="advertismentItemsBottom")
    // Товар дня
    //.col-12.carousel.q-my-xl(v-if="season?.products")
    //    product-slider-skeleton-desktop(
    //        title
    //        :height="350"
    //        v-if="!season?.products?.length")
    //    product-slider-desktop(
    //        v-else-if="season?.products?.length"
    //        label="Товар дня"
    //        :slides="season.products"
    //        :sliderProductDay="true"
    //        :manufacture="true")

    // Сезонные товары
    .col-12.carousel.q-my-xl(v-if="season?.products")
        product-slider-skeleton-desktop(
            title
            :height="350"
            v-if="!season?.products?.length")
        product-slider-desktop(
            v-else-if="season?.products?.length"
            label="Сезонные товары"
            :slides="season.products"
            :manufacture="true")
    //- .font-24.text-primary.text-weight-medium.q-my-xl
    //-     | Почему выбирают онлайн-аптеку {{ link }}?
    articles-desktop(
        v-if="articles"
        :articles="articles"
    )
    h1.font-32.text-weight-bold.line-height-44.q-mt-xl.text-primary(v-if="!isActivAccessibility")
        | Интернет-аптека etabl.ru
    .row.text-primary
        .col-6(style="padding-right: 12px").text-justify
            .font-24.line-height-36.text-weight-bold
                | Откройте мир здоровья и комфорта с etabl.ru!
            .q-mt-md
                | Интернет-аптека etabl.ru — это современная онлайн-платформа для заказа лекарств и медицинских товаров с удобной доставкой. 
                | Мы создаем комфортные условия для покупки необходимых препаратов, делая здоровье доступным каждому.
            .font-24.line-height-36.text-weight-bold.q-mt-lg
                | Почему выбирают etabl.ru?
            .q-mt-sm
                span.text-weight-medium
                    | Широкий ассортимент товаров: 
                span
                    | на etabl.ru вы найдете рецептурные и безрецептурные лекарства, биологически активные добавки (БАДы), витамины, средства 
                    | личной гигиены, косметику и медицинское оборудование.
            .q-mt-sm
                span.text-weight-medium
                    | Удобный поиск: 
                span
                    | благодаря интуитивно понятному интерфейсу и фильтрам по категориям, производителям и ценам, вы сможете легко
                    | найти нужные товары за считанные минуты.
            .q-mt-sm
                span.text-weight-medium
                    | Описание товаров: 
                span
                    | каждая страница товара содержит подробное описание, рекомендации по применению и актуальные цены, что упрощает процесс выбора.  
            .q-mt-sm
                | Делайте заказы на etabl.ru — для удобного и быстрого доступа к медицинским товарам!
            .q-mt-sm
                | Мы делаем все, чтобы процесс покупки был максимально комфортным и быстрым. Оформляйте заказы в несколько кликов и получайте
                | лекарства с минимальными усилиями!
        .col-6(style="padding-left: 12px").text-justify
            .font-24.line-height-36.text-weight-bold
                | Преимущества интернет-аптеки etabl.ru
            .q-mt-md
                span.text-weight-medium
                    | Доступные цены: 
                span
                    | Мы предлагаем конкурентоспособные цены на все товары. Регулярные акции и скидки позволяют покупать необходимые лекарства 
                    | по сниженной стоимости. Следите за нашими специальными предложениями и программами лояльности, чтобы сэкономить еще больше.
            .q-mt-md
                span.text-weight-medium
                    | Быстрая доставка по всей России: 
                span
                    | Жители Курской, Брянской, Белгородской областей и других регионов могут заказать лекарства с доставкой прямо на дом. Вся
                    | информация о заказе и статус доставки доступны в личном кабинете, что обеспечивает полный контроль и прозрачность.
            .q-mt-md
                span.text-weight-medium
                    | Удобство заказа: 
                span
                    | Оформить заказ на etabl.ru легко и быстро: Используйте удобный поиск или выберите товары из каталога. Добавьте необходимые
                    | позиции в корзину. Выберите подходящий способ оплаты и доставки.
            .q-mt-md
                span.text-weight-medium
                    | Альтернативная корзина и ТГ Бот Алина: 
                    |
                span
                    | Мы сможем предложить проверенные альтернативы отложенных вами товаров в корзину и сэкономим ваши деньги. Наш Telegram Бот- ваш надежный помощник - поможет управлять заказами и проинформирует о наших акциях.
    DownLoadApp
    .row.relative-position.q-mt-lg.full-block.justify-between
        .col-4.column.relative-position.full-block__content.reason(v-for="(item, key) in reasons")
            .row.justify-center(style="min-height: 130px; max-height='156px'")
                img(
                    :src="`interface/about/${item.icon_top}.svg`"
                )
                .col-9.column
                    .font-20.text-primary.text-weight-medium.text-center.q-mx-auto.title-top(:class="{ 'mw-1' : (key===2) }")
                        | {{ item.title_top }}
                    div.text-center
                        span.font-16.q-mt-md.text-center.block
                            | {{ item.subtitle_top}}
    .col-12.carousel.q-mt-xl(v-if="history?.length && userAuthorized")
        product-slider-desktop(
            label="Вы недавно просматривали"
            :slides="history"
            :manufacture="true")
    Alphabet

</template>

<script>
import { computed, onMounted, ref, defineAsyncComponent, hydrateOnVisible } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const DownLoadApp = defineAsyncComponent({
    loader: () => import('components/DownloadMobileApp/DownLoadApp.vue'),
    hydrate: hydrateOnVisible(),
});
const BaseBtn = defineAsyncComponent({
    loader: () => import('components/Base/BaseBtn.vue'),
    hydrate: hydrateOnVisible(),
});

const CarouselDesktop = defineAsyncComponent({
    loader: () => import('components/Carousel/CarouselDesktop.vue'),
    hydrate: hydrateOnVisible(),
});

const CarouselSkeleton = defineAsyncComponent({
    loader: () => import('components/Carousel/CarouselSkeleton.vue'),
    hydrate: hydrateOnVisible(),
});

const ProductSliderDesktop = defineAsyncComponent({
    loader: () => import('components/ProductSlider/ProductSliderDesktop.vue'),
    hydrate: hydrateOnVisible(),
});

const ProductSliderSkeletonDesktop = defineAsyncComponent({
    loader: () => import('components/ProductSlider/ProductSliderSkeletonDesktop.vue'),
    hydrate: hydrateOnVisible(),
});

const ArticlesDesktop = defineAsyncComponent({
    loader: () => import('components/Articles/ArticlesDesktop.vue'),
    hydrate: hydrateOnVisible(),
});

const FastLinks = defineAsyncComponent({
    loader: () => import('components/FastLinks/FastLinks.vue'),
    hydrate: hydrateOnVisible(),
});

const Alphabet = defineAsyncComponent({
    loader: () => import('components/Alphabet/Alphabet.vue'),
    hydrate: hydrateOnVisible(),
});
export default {
    name: 'HomeDesktop',
    components: {
        DownLoadApp,
        Alphabet,
        BaseBtn,
        ProductSliderDesktop,
        CarouselDesktop,
        CarouselSkeleton,
        ProductSliderSkeletonDesktop,
        ArticlesDesktop,
        FastLinks,
    },
    setup() {
        const router = useRouter();
        const store = useStore();

        const discount = computed(() => store.getters['advertisement/DISCOUNT']);
        const history = computed(() => store.getters['advertisement/HISTORY']);
        const advertisment = computed(() => store.getters['advertisement/ADVERTISMENT']);
        const articles = computed(() => store.getters['advertisement/ARTICLES']);
        const season = computed(() => store.getters['advertisement/SEASONS']);


        // город пользователя
        const city = computed(() => store.getters['cityForm/CITY']);

        // авторизован ли пользователь
        const userAuthorized = computed(() => store.getters['user/USER_AUTHORIZED']);

        const advertismentItemsTop = computed(() => {
            return (advertisment.value?.result || [])
                .filter((item) => {
                    return item.viewLocation === 'top'
                        && item.image;
                });
        });
        const advertismentItemsBottom = computed(() => {
            const result = (advertisment.value?.result || [])
                .filter((item) => {
                    return item.viewLocation === 'bottom'
                        && item.image;
                });
            return result.length ? result : [];
        });

        const userCityId = computed(() => store.getters['user/USER_CITY_ID']);
        const isActivAccessibility = computed(() => store.getters['styles/isActiv']);

        const goTo = (path) => {
            router.push(`/marketing/${path}`);
        };

        const ceo = [
            {
                title: 'лекарственных средств,<br>витаминов и БАД',
                img: 'ceo/1.svg',
                link: 'catalog/lekarstva-i-bady=000000001',
            }, {
                title: 'профессиональной<br>косметики',
                img: 'ceo/2.svg',
                link: 'catalog/krasota-i-u%D1%85od=000000199',
            }, {
                title: 'товаров для малышей, будущих<br>мам и кормящих женщин',
                img: 'ceo/3.svg',
                link: 'catalog/gigiena=000000314',
            }, {
                title: 'медицинских портативных приборов,<br>предметов ухода за больными',
                img: 'ceo/4.svg',
                link: 'catalog/pribory-medicinskie=000000346',
            },
        ];
        const link = `${process.env.APP_SERVICE_HOSTNAME}`;
        const reasons = ref([
            {
                icon_top: 'reasons-1',
                title_top: 'Надежность',
                subtitle_top: 'Мы сотрудничаем только с проверенными производителями и официальными поставщиками.',
                icon_bottom: '1_bot',
                title_bottom: 'Программа лояльности скидки до 20%',
                subtitle_bottom: 'Гибкая и выгодная программа для клиентов',
            }, {
                icon_top: 'reasons-2',
                title_top: 'Качество',
                subtitle_top: 'Все товары проходят строгий контроль и соответствуют стандартам.',
                icon_bottom: '2_bot',
                title_bottom: 'Бронирование заказов круглосуточно',
                subtitle_bottom: 'Бронируйте заказ на сайте или по телефону ',
            }, {
                icon_top: 'reasons-3',
                title_top: 'Удобство',
                subtitle_top: 'Доставка до дверей вашего дома без лишних затрат времени.',
                icon_bottom: '3_bot',
                title_bottom: 'Консультация квалифицированного специалиста',
                subtitle_bottom: 'Качественный сервис',
            },
        ]);
        const countPreviewTop = ref(0);
        return {
            countPreviewTop,
            history,
            discount,
            season,
            advertisment,
            advertismentItemsTop,
            advertismentItemsBottom,
            goTo,
            link,
            reasons,
            userAuthorized,
            ceo,
            userCityId,
            articles,
            isActivAccessibility,
            city,
        };
    },

};
</script>
<style lang="scss" scoped>
:deep(.q-page.mobile) {
    min-height: 100px !important;
}

.carousel {
    &__title {
        @media screen and (max-width: $breakpoint-mobile) {
            font: {
                size: 32px;
                weight: 700;
            }
            line-height: 44px;
        }
    }
}

.carousel__title {
    @media (max-width: 1280px) {
        font-size: 1rem;
    }
}

.ceo {
    &__text {
        width: 747px;

        @media screen and (max-width: 1280px) {
            width: 485px;
        }
    }

    &__img {
        height: 298px;
        margin-right: 8px;

        @media screen and (max-width: 1440px) {
            height: 278px;
        }
        @media screen and (max-width: 1280px) {
            height: 240px;
            margin-top: 48px;
        }
    }
}

.title-top {
    max-width: 234px;
}

.mw-1 {
    max-width: 227px;
}

.additional {
    background: #E2F0E8;
    border-radius: 16px;
    overflow: hidden;
    height: 184px;
}

</style>
