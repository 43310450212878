<template lang="pug">
index-desktop(v-if="q.platform.is.desktop")
index-mobile(v-if="q.platform.is.mobile")
</template>

<script>
import {LoadingBar, Platform, useMeta, useQuasar} from 'quasar';
import {computed} from 'vue';
import {useStore} from 'vuex';
import {getValid_In} from 'assets/helpers/blackListCitys';
import {useRouter} from 'vue-router';

import IndexMobile from './IndexMobile.vue';
import IndexDesktop from './IndexDesktop.vue';

export default {
    name: 'Home',
    components: {
        IndexMobile,
        IndexDesktop,
    },
    async preFetch({store, ssrContext, currentRoute, redirect}) {

        let locations = store.getters['cityForm/LOCATIONS'];

        let cityValid = !currentRoute.params.city;

        let cityStr = currentRoute.path.replaceAll('/', '');

        const invalidParam = ['openInBrowser', 'amp'];

        const {query} = currentRoute;

        const hasInvalidParam = invalidParam.some(param => param in query);


        if (!cityValid) {
            locations.results.forEach((region) => {
                region.cities.forEach((city) => {
                    if (city.slug == cityStr) {
                        cityValid = true;
                    } else if (city.slug == currentRoute.params.city) {
                        cityValid = true;
                    }
                });
            });
        }
        ;

        if (!cityValid) {
            return Promise.reject({code: 404});
        }

        if (hasInvalidParam) {
            let city = currentRoute.params?.city ?? '/';
            redirect(`/${city}`, 301);
        }

        // locations.for

        LoadingBar.setDefaults({
            color: 'positive',
            size: '4px',
            position: 'top',
        });

        const platform = process.env.SERVER
            ? Platform.parseSSR(ssrContext)
            : Platform;

        let channel = null;

        if (platform.is.mobile)
            channel = 'app';
        if (platform.is.desktop)
            channel = 'web';

        LoadingBar.start();

        try {
            await store.dispatch('advertisement/FETCH_ADVERTISMENT', channel);
            await Promise.all([
                store.dispatch('advertisement/GET_ARTICLES'),
                store.dispatch('advertisement/GET_ARTICLES_TAGS'),
                store.dispatch('advertisement/GET_MARKERS'),
                store.dispatch('advertisement/GET_CURRENT_SEASON'),
                (async () => {
                    if (!store.getters['advertisement/CACHE'] || new Date() - store.getters['advertisement/CACHE'] >= 900000) {
                        store.commit('advertisement/SET_CACHE', new Date());
                        await store.dispatch('advertisement/FETCH_ADVERTISMENT', channel);
                    }
                })(),
                store.getters['user/USER_AUTHORIZED'] && store.dispatch('advertisement/GET_HISTORY'),
            ]);
            // Все запросы выполнены успешно
        } catch (error) {
            // Обработка ошибок, если один из запросов завершится с ошибкой
        } finally {
            LoadingBar.stop();
        }

    },
    setup() {
        const router = useRouter();
        const store = useStore();
        const city = computed(() => store.getters['cityForm/CITY']);

        useMeta(() => {
            return {
                title: `Интернет-аптека eTabl.ru (партнер Таблеточка) | Поиск и заказ лекарств в ${getValid_In(city?.value?.name ? city.value.name : 'Белгород')} и других городах`,
                meta: {
                    description: {
                        name: 'description',
                        content: `Купить лекарства по лучшим ценам в онлайн-аптеке eTabl.ru (партнер Таблеточка) в ${getValid_In(city?.value?.name ? city.value.name : 'Белгород')}. Поиск и заказ лекарственных препаратов через интернет с доставкой на дом и в аптеку, удобный каталог, акции и скидки! ✅Гарантия качества✅Удобный поиск☎️8 (800) 200-5-400`,
                    },
                    keywords: {
                        name: 'keywords',
                        content: 'Онлайн аптека, заказ лекарств, интернет, купить, лекарственные препараты',
                    },
                    og_locale: {property: 'og:locale', content: 'ru_RU'},
                    og_type: {property: 'og:type', content: 'website'},
                    og_site_name: {property: 'og:site_name', content: 'eTabl.ru'},
                    og_title: {
                        property: 'og:title',
                        content: `Интернет-аптека eTabl.ru (партнер Таблеточка) | Поиск и заказ лекарств в ${getValid_In(city?.value?.name ? city.value.name : 'Белгород')} и других городах`,
                    },
                    og_description: {
                        property: 'og:description',
                        content: `Купить лекарства по лучшим ценам в онлайн-аптеке eTabl.ru (партнер Таблеточка) в ${getValid_In(city?.value?.name ? city.value.name : 'Белгород')}. Поиск и заказ лекарственных препаратов через интернет с доставкой на дом и в аптеку, удобный каталог, акции и скидки! ✅Гарантия качества✅Удобный поиск☎️8 (800) 200-5-400`,
                    },
                    og_url: {property: 'og:url', content: `https://${process.env.APP_SERVICE_HOSTNAME}${router.currentRoute.value.fullPath}`},
                    og_image: { property: 'og:image', content: 'https://etabl.ru/logo.svg' },
                },
                script: {
                    ldJson: {
                        type: 'application/ld+json',
                        innerHTML: `{
                                "@context": "https://schema.org/",
                                "@type": "WebSite",
                                "url": "https://${process.env.APP_SERVICE_HOSTNAME}",
                                "potentialAction": {
                                    "@type": "SearchAction",
                                    "target": "https://${process.env.APP_SERVICE_HOSTNAME}/search?query=",
                                    "query": "required",
                                    "query-input": {
                                        "@type": "PropertyValueSpecification",
                                        "valueName": "query",
                                        "valueRequired": true
                                    }
                                }
                            }`,
                    },
                },
            };
        });

        return {
            q: useQuasar(),
        };
    },


};
</script>
